import PropTypes from "prop-types"
import { connectionShape } from "app/lib/shared_prop_types"
import uniqBy from "lodash/uniqBy"

export const WorksWithContent = ({ applet, lazy = true, mainService }) => {
  let channels = applet.channels ? uniqBy(applet.channels, "module_name") : []

  // Put the main service as first item from channels list
  channels = channels.filter(el => el.module_name !== mainService.module_name)
  channels.unshift(mainService)

  const mainPermissions = channels.length === 3 ? channels : channels.slice(0, 2)
  const remainingPermsNumber = channels.length - mainPermissions.length

  return (
    channels.length > 0 && (
      <div className="works-with">
        <ul className="permissions">
          {mainPermissions.map(p => (
            <li key={`permission-${p.module_name}`}>
              <img
                src={p.monochrome_image_url}
                width="26"
                height="26"
                loading={lazy ? "lazy" : "eager"}
                alt={p.name}
                title={p.name}
              />
            </li>
          ))}
          {remainingPermsNumber > 0 && <li>{remainingPermsNumber}+</li>}
        </ul>
      </div>
    )
  )
}

WorksWithContent.propTypes = {
  applet: connectionShape.isRequired,
  mainService: PropTypes.shape({
    module_name: PropTypes.string.isRequired,
    monochrome_image_url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  lazy: PropTypes.bool,
}
