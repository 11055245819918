import PropTypes from "prop-types"

export const connectionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  author_id: PropTypes.string,
  brand_color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon_url: PropTypes.string,
})

export const serviceShape = PropTypes.shape({
  id: PropTypes.string,
  brand_color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  module_name: PropTypes.string,
})

export const storyFieldShape = PropTypes.shape({
  applets: PropTypes.array,
  channels: PropTypes.array,
  text: PropTypes.string,
  type: PropTypes.string,
})

export const partialStoryShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  discoverable: PropTypes.string,
})

export const fullStoryShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  discoverable: PropTypes.string,
  fields: PropTypes.arrayOf(storyFieldShape).isRequired,
  related: PropTypes.arrayOf(partialStoryShape),
})

export const userShape = PropTypes.shape({
  id: PropTypes.string.isRequired, // current_user.id is a int but as string ╮(′～‵〞)╭
  login: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  email_for_org_users: PropTypes.string,
  is_admin: PropTypes.bool,
  is_platform_developer: PropTypes.bool,
  is_maker: PropTypes.bool,
  profile_image_url: PropTypes.string,
  timezone: PropTypes.string,
  created_at: PropTypes.string,
  available_clients: PropTypes.string,
})

export const ingredientShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  normalized_name: PropTypes.string,
  label: PropTypes.string,
  note: PropTypes.string,
  slug: PropTypes.string,
  trigger_id: PropTypes.string,
  value_type: PropTypes.string,
  example: PropTypes.string,
})

export const ingredientsMetadataShape = PropTypes.shape({
  trigger: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    ingredients: PropTypes.arrayOf(ingredientShape),
    service: PropTypes.shape({
      name: PropTypes.string,
      background_color: PropTypes.string.isRequired,
      icon_url: PropTypes.string.isRequired,
    }),
  }),
  queries: PropTypes.arrayOf(
    PropTypes.shape({
      ingredients: PropTypes.arrayOf(ingredientShape),
      name: PropTypes.string.isRequired,
      service: PropTypes.shape({
        name: PropTypes.string,
        background_color: PropTypes.string.isRequired,
        icon_url: PropTypes.string.isRequired,
      }),
    })
  ),
})

export const stepFieldShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  field_type: PropTypes.string,
  normalized_field_type: PropTypes.string.isRequired,
  field_ui_type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  shareable: PropTypes.bool,
  hideable: PropTypes.bool,
  can_have_default: PropTypes.bool,
  helper_text: PropTypes.string,
})

export const tqaShape = PropTypes.shape({
  module_name: PropTypes.string.isRequired,
  full_module_name: PropTypes.string.isRequired,
  full_normalized_module_name: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      helper_text: PropTypes.string,
      field_type: PropTypes.string.isRequired,
      field_type_definition_slug: PropTypes.string.isRequired,
      required: PropTypes.bool,
      can_have_default: PropTypes.bool,
      hideable: PropTypes.bool,
    })
  ).isRequired,
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      normalized_name: PropTypes.string.isRequired,
      value_type: PropTypes.string.isRequired,
      note: PropTypes.string,
      example: PropTypes.string,
    })
  ),
})
