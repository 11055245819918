import { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import parse from "html-react-parser"

import { compactInteger } from "humanize-plus"
import { connectionShape } from "app/lib/shared_prop_types"
import appletOrConnection from "shared/lib/use_applet_or_connection"
import userIconPng from "images/web/applet_card/user-icon.png"
import proPlusBadge from "images/plan-badges/pro_plus.svg"
import proBadge from "images/plan-badges/pro.svg"

import "./small_applet_card.scss"

export default class Meta extends Component {
  render() {
    const { applet } = this.props
    const humanizedInstallNumber = compactInteger(applet.installs_count, 1)
    const appletType = appletOrConnection(applet)
    const needsProBadge = applet.pro_features || applet.intermediate_pro_features

    return (
      <div className={classnames("meta", { badged: needsProBadge })}>
        {applet.installs_count > 0 && (
          <div
            className="installs"
            aria-label={`${humanizedInstallNumber} users`}
            title={`${humanizedInstallNumber} users enabled this ${appletType}`}
          >
            <span className="verified">
              <img src={userIconPng} alt="user icon" loading="lazy" width="14" height="18" />
            </span>
            <span>{humanizedInstallNumber}</span>
          </div>
        )}
        {needsProBadge && (
          <span title={`Applet has pro features`} className="card-badge">
            {parse(applet.pro_features ? proPlusBadge : proBadge)}
          </span>
        )}
      </div>
    )
  }
}

Meta.propTypes = {
  applet: connectionShape.isRequired,
  mainService: PropTypes.object,
}
