import { Component } from "react"
import PropTypes from "prop-types"

import isEmpty from "lodash/isEmpty"

// Component renders the author info of connection. It can be:
//  1. Active user
//  2. IFTTT or other service
//  3. Deleted user.
//
// Applets from deleted users aren't accessable. This component replicates the old logic to be safe.
export default class AuthorInfo extends Component {
  render() {
    const { connection, linkTo, placeholderForDeletedUsers } = this.props
    const serviceIcon =
      connection.config_type === "dynamic"
        ? this.props.serviceIcon // use connection_flow.owner_icon
        : connection.monochrome_icon_url // use normalized applet logic

    if (connection.by_service_owner && connection.author === "IFTTT") {
      return UserAuthorInfo(connection, linkTo)
    } else if (connection.by_service_owner) {
      return ServiceAuthorInfo(connection, linkTo, serviceIcon)
    } else if (connection.author != placeholderForDeletedUsers) {
      return UserAuthorInfo(connection, linkTo)
    } else {
      return UserDeletedInfo(placeholderForDeletedUsers)
    }
  }
}

function ServiceAuthorInfo(connection, linkTo, serviceIcon) {
  return (
    <>
      {linkTo ? (
        <div className="owner service-link">
          <a className="author-link link-standalone link-on-color" title="Visit Service Page" href={linkTo.url}>
            <img
              alt={connection.author}
              title={connection.author}
              width="20"
              height="20"
              loading="lazy"
              src={serviceIcon}
            />{" "}
            <span className="author">{connection.author}</span>
          </a>
        </div>
      ) : (
        <div className="owner no-link">
          <img
            alt={connection.author}
            title={connection.author}
            width="20"
            height="20"
            loading="lazy"
            src={serviceIcon}
          />{" "}
          <span className="author">{connection.author}</span>
        </div>
      )}
    </>
  )
}

function UserAuthorInfo(connection, linkTo) {
  if (isEmpty(connection.author)) {
    return <></>
  }

  let linkComponent = <>{connection.author}</>
  if (linkTo && linkTo.url) {
    linkComponent = <a href={linkTo.url}>{connection.author}</a>
  }
  // DO Applets have nil authors!
  return (
    <div className="owner byline by-and-author-link">
      <span>
        <span className="by">by</span>
        <span className="author">{linkComponent}</span>
      </span>
    </div>
  )
}

function UserDeletedInfo(placeholderForDeletedUsers) {
  return (
    <div className="owner">
      <span>
        by <span className="author">{placeholderForDeletedUsers}</span>
      </span>
    </div>
  )
}

AuthorInfo.propTypes = {
  connection: PropTypes.shape({
    author: PropTypes.string,
    author_tier: PropTypes.string,
  }),
  linkTo: PropTypes.shape({
    url: PropTypes.string,
  }),
  serviceIcon: PropTypes.string,
  placeholderForDeletedUsers: PropTypes.string.isRequired,
}

AuthorInfo.defaultProps = {
  placeholderForDeletedUsers: "IFTTT Community",
  connection: { author_tier: "free" },
}
