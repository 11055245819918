export default function setupUnifiedMobileMenu(id) {
  setupMobileMenu(id)
}

function setupMobileMenu(id) {
  let opened = false
  const menuRef = document.querySelector(id)

  const updateOpenStatus = state => {
    opened = state
    if (opened) {
      window.document.body.classList.add("mobile-menu-opened")
      menuRef.classList.add("open")
      menuRef.classList.remove("closed")
    } else {
      window.document.body.classList.remove("mobile-menu-opened")
      menuRef.classList.add("closed")
      menuRef.classList.remove("open")
    }
  }

  const flip = e => {
    updateOpenStatus(!opened)
  }

  menuRef.addEventListener("click", flip)
}
