export default function BackButton(fallbackPath) {
  const referrer = document.referrer
  const referrerHostname = referrer ? new URL(referrer).hostname : null

  if (referrerHostname?.endsWith(window.location.hostname)) {
    window.history.back()
  } else {
    location.href = fallbackPath
  }
}
