import { Component } from "react"
import PropTypes from "prop-types"
import { captureException } from "@sentry/react"

export default class ErrorBoundary extends Component {
  state = { hasError: false }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    captureException(error)
    if (window.App.allowDebugErrorMessages) {
      console.warn(info)
    }
  }
  render() {
    if (this.state.hasError) {
      return this.props.placeholder
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  placeholder: PropTypes.node,
  children: PropTypes.node,
}

ErrorBoundary.defaultProps = {
  placeholder: <p>{"Something's not just exactly right here"}</p>,
}
