import { Component } from "react"
import PropTypes from "prop-types"
import Fittty from "shared/components/fittty"

import classnames from "classnames"

import colors from "foundation/_colors_export.scss?variables"
import defaultCSS from "./connect_button.scss"
import smallFlavorCSS from "./small_connect_button.scss"
import { ctaCopyToConnect } from "../utils"

export default class ConnectButton extends Component {
  applyCSS = style => {
    return this.props.smallFlavor ? smallFlavorCSS[style] : defaultCSS[style]
  }

  render() {
    const mainService = this.props.mainService
    const knobBgColor = this.props.knobBgColor || {
      backgroundColor: `#${mainService.brand_color}`,
    }
    const ctaCopy = this.props.connected
      ? "Connected"
      : ctaCopyToConnect(!this.props.smallFlavor && this.props.dynamicConfig && mainService.short_name)

    return (
      <div className={classnames("platform-preview", this.applyCSS("connect-button-outline"))}>
        <div
          style={this.props.defaultBgColor}
          className={classnames("platform-preview", this.applyCSS("connect-button"))}
          onClick={this.handleClick}
        >
          <div
            id="connect-ball"
            className={classnames({
              [this.applyCSS("service-icon")]: true,
              [this.applyCSS("alt-brand-color")]: this.props.knobBgColorForDarkBrands,
              [this.applyCSS("connected")]: this.props.connected,
            })}
            style={knobBgColor}
          >
            {this.props.dynamicConfig && (
              <img
                width="40"
                height="40"
                src={mainService.lrg_monochrome_image_url || mainService.lrg_variant_image_url}
                alt={mainService.name}
                title={mainService.name}
              />
            )}
          </div>
          <div
            className={classnames({
              [this.applyCSS("connect-cta")]: true,
              [this.applyCSS("connected")]: this.props.connected,
            })}
          >
            <div>
              <Fittty maxSize={this.props.connectButtonMaxFontSize} minSize={14} text={ctaCopy} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleClick = event => {
    event.preventDefault()
    this.props.onConnectClick(event)
  }
}

ConnectButton.propTypes = {
  connectButtonMaxFontSize: PropTypes.number,
  connected: PropTypes.bool,
  defaultBgColor: PropTypes.object,
  knobBgColor: PropTypes.object,
  knobBgColorForDarkBrands: PropTypes.bool,
  mainService: PropTypes.object.isRequired,
  onConnectClick: PropTypes.func.isRequired,
  dynamicConfig: PropTypes.bool,
  smallFlavor: PropTypes.bool,
}

ConnectButton.defaultProps = {
  connectButtonMaxFontSize: 30,
  connected: false,
  defaultBgColor: { backgroundColor: colors.primaryTextColor },
  onConnectClick: () => {},
  smallFlavor: false,
}
