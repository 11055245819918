import PropTypes from "prop-types"

import classnames from "classnames"

import { connectionShape } from "app/lib/shared_prop_types"

import Fittty from "shared/components/fittty"
import NeverEnabledMeta from "./never_enabled_meta"
import EnabledMeta from "./enabled_meta"
import AuthorInfo from "shared/components/connection_card/author_info"
import { WorksWithContent } from "./works_with_content"

import "./small_applet_card.scss"

// styleName and className need to be passed manually
// otherwise the transpiler does not detect the properties
const LinkableAppletWrapper = ({ children, href, onClick, className, ...attributes }) => {
  if (href || onClick) {
    return (
      <a className={className} styleName="applet-card-body" onClick={onClick} href={href} {...attributes}>
        {children}
      </a>
    )
  } else {
    return (
      <div className={className} styleName="applet-card-body" {...attributes}>
        {children}
      </div>
    )
  }
}

export default function SmallAppletCard(props) {
  const {
    applet,
    appletPath,
    availableToRestore,
    extraClass,
    isOnboardingApplet,
    onAppletClick,
    overrideToConsumerSite,
    showConnectButton,
    showRestoreButton,
    target,
    useDeactivatedColor,
    ...linkProps
  } = props
  const mainService =
    applet.channels.find(el => {
      return el.module_name === applet.service_slug
    }) || applet.channels[0]
  const backgroundImage =
    applet.background_images?.background_image_url_1x || applet.background_images?.sm_background_image_url
  const appletLink = overrideToConsumerSite ? `//ifttt.com${appletPath}` : appletPath
  const disconnectedState = applet.status === "disabled_for_user" || useDeactivatedColor
  const style = disconnectedState ? { backgroundColor: "#aaa" } : { backgroundColor: `#${mainService.brand_color}` }

  if (backgroundImage) {
    if (disconnectedState) {
      // rgba(170, 170, 170, .58) is #aaa with 42% alpha
      style.backgroundImage = `linear-gradient(to bottom, rgba(170, 170, 170, .58), rgba(170, 170, 170, .58)), url('${backgroundImage}')`
    } else {
      style.backgroundImage = `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.4)), url('${backgroundImage}')`
    }
  }

  return (
    <LinkableAppletWrapper
      style={style}
      className={classnames({
        "applet-card-body": true,
        [`applet-${applet.id}`]: true,
        [`${extraClass}`]: true,
        "applet-card-no-link": !props.appletPath,
      })}
      href={appletLink}
      onClick={props.onAppletClick}
      target={target}
      {...linkProps}
    >
      <div className="meta-header">
        <WorksWithContent applet={applet} mainService={mainService} />
      </div>
      <div className="content">
        <span
          className={classnames("title", {
            "with-image": backgroundImage,
          })}
          styleName={classnames({
            "with-image": backgroundImage,
          })}
        >
          <Fittty multiLine text={applet.name} minSize={14} />
        </span>
        <AuthorInfo connection={applet} serviceIcon={applet.monochrome_icon_url} />
      </div>
      {showRestoreButton && (
        <div styleName="restore-button">
          <button className="button-tertiary" styleName={classnames({ disabled: !availableToRestore })}>
            {availableToRestore ? "Restore" : "Can't restore"}
          </button>
        </div>
      )}
      {!applet.status || (applet.status === "never_enabled_for_user" && !isOnboardingApplet) ? (
        <NeverEnabledMeta applet={applet} />
      ) : (
        <EnabledMeta
          applet={applet}
          mainService={mainService}
          href={appletLink}
          onAppletClick={onAppletClick}
          appearDisabled={disconnectedState}
          showConnectButton={showConnectButton}
          isOnboardingApplet={isOnboardingApplet}
        />
      )}
    </LinkableAppletWrapper>
  )
}

SmallAppletCard.propTypes = {
  applet: connectionShape.isRequired,
  appletPath: PropTypes.string,
  availableToRestore: PropTypes.bool,
  extraClass: PropTypes.string,
  isOnboardingApplet: PropTypes.bool,
  onAppletClick: PropTypes.func,
  overrideToConsumerSite: PropTypes.bool,
  showConnectButton: PropTypes.bool,
  showRestoreButton: PropTypes.bool,
  target: PropTypes.string,
  term: PropTypes.string,
  useDeactivatedColor: PropTypes.bool,
}

SmallAppletCard.defaultProps = {
  availableToRestore: false,
  extraClass: "",
  isOnboardingApplet: false,
  onAppletClick: () => {},
  overrideToConsumerSite: false,
  showConnectButton: true,
  showRestoreButton: false,
  useDeactivatedColor: false,
}
