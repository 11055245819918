import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import Cookies from "js-cookie"

function ResolveTimezone() {
  if (Cookies.get("timezone")) {
    return
  }

  dayjs.extend(utc)
  dayjs.extend(timezone)
  const timezoneName = dayjs.tz.guess()

  if (timezoneName !== null) {
    Cookies.set("timezone", timezoneName, { expires: 14 })
  }
}

export default ResolveTimezone
