import { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { connectionShape } from "app/lib/shared_prop_types"
import { formatHexColorValue } from "shared/lib/alternate_brand_color"
import ConnectButton from "shared/components/connection_card/connect_button/index"
import parse from "html-react-parser"

import { compactInteger } from "humanize-plus"
import appletOrConnection from "shared/lib/use_applet_or_connection"
import userIconPng from "images/web/applet_card/user-icon.png"
import proPlusBadge from "images/plan-badges/pro_plus.svg"
import proBadge from "images/plan-badges/pro.svg"

import "./small_applet_card.scss"

export default class Meta extends Component {
  render() {
    const { appearDisabled, applet, href, mainService, onAppletClick, showConnectButton, isOnboardingApplet } =
      this.props

    const humanizedInstallNumber = compactInteger(applet.installs_count, 1)
    const appletType = appletOrConnection(applet)
    const isConnected = applet.status === "enabled_for_user"
    const background = applet.background_images?.sm_background_image_url ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0.3)"
    const handleOnClick = event => {
      event && onAppletClick(event)
      href && window.location.assign(href)
    }
    const needsProBadge = applet.pro_features || applet.intermediate_pro_features

    return (
      <>
        {showConnectButton && (
          <div styleName="small-connect-button">
            <ConnectButton
              smallFlavor
              mainService={mainService}
              onConnectClick={handleOnClick}
              connected={isConnected}
              defaultBgColor={{ background }}
              knobBgColor={{
                boxShadow: "none",
                backgroundColor:
                  (isConnected && !appearDisabled) || isOnboardingApplet
                    ? formatHexColorValue(mainService.brand_color)
                    : "#AAA",
              }}
              knobBgColorForDarkBrands={mainService.brand_color.indexOf("000") !== -1}
              connectButtonMaxFontSize={14}
            />
          </div>
        )}
        <div className={classnames("meta", { badged: needsProBadge, "onboarding-applet": isOnboardingApplet })}>
          {applet.installs_count > 0 && (
            <div
              className="installs"
              aria-label={`${humanizedInstallNumber} users`}
              title={`${humanizedInstallNumber} users enabled this ${appletType}`}
            >
              <span className="verified">
                <img src={userIconPng} alt="user icon" loading="lazy" width="14" height="18" />
              </span>
              <span>{humanizedInstallNumber}</span>
            </div>
          )}
          {needsProBadge && (
            <span title={`Applet has pro features`} className="card-badge">
              {parse(applet.pro_features ? proPlusBadge : proBadge)}
            </span>
          )}
        </div>
      </>
    )
  }
}

Meta.propTypes = {
  appearDisabled: PropTypes.bool,
  applet: connectionShape.isRequired,
  href: PropTypes.string,
  isOnboardingApplet: PropTypes.bool,
  mainService: PropTypes.object,
  onAppletClick: PropTypes.func,
  showConnectButton: PropTypes.bool,
}

Meta.defaultProps = {
  appearDisabled: false, // the applet appears disabled to show the user they will lose the applet
  isOnboardingApplet: false,
  showConnectButton: true,
}
