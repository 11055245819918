import PropTypes from "prop-types"

export default function XIcon({ width, height, color, strokeWidth }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Close icon</title>
      <g
        stroke={color}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <path d="M1.598 1.598l7.717 7.717M9.447 1.598L1.73 9.315" />
      </g>
    </svg>
  )
}

XIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  strokeWidth: PropTypes.string,
}

XIcon.defaultProps = {
  width: "11",
  height: "11",
  color: "#222",
  strokeWidth: "2",
}
