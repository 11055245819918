export default function setupUnifiedHeaderDropdown(id: string): void {
  let opened = false
  const menuRef = document.querySelector(id)
  if (!menuRef) return

  const menuTitles = menuRef.getElementsByClassName("menu-title")
  const rightGroup = document.getElementsByClassName("right-group")[0]

  const updateOpenStatus = (state: boolean) => {
    opened = state
    if (opened) {
      menuRef.classList.add("open")
      menuRef.classList.remove("closed")
      rightGroup.classList.add("open")
      rightGroup.classList.remove("closed")
      window.document.addEventListener("click", closeMenu)
    } else {
      menuRef.classList.add("closed")
      menuRef.classList.remove("open")
      rightGroup.classList.add("closed")
      rightGroup.classList.remove("open")
      window.document.removeEventListener("click", closeMenu)
    }
  }

  const closeMenu = (e: Event) => {
    if (!opened) return

    const isOutsideMenu = !menuRef.contains(e.target as Node)

    if (isOutsideMenu) {
      updateOpenStatus(false)
    }
  }

  const flip = (e: Event) => {
    const el = e.target as HTMLElement
    if (el.classList.contains("item")) return

    updateOpenStatus(!opened)
  }

  for (let i = 0; i < menuTitles.length; i++) {
    menuTitles[i].addEventListener("click", flip)
  }
}
