export default function LazyBGImageObserver(): void {
  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const el = entry.target
          el.classList.remove("lazy-bg-img")
          observer.unobserve(el)
        }
      })
    },
    { threshold: 0 }
  )

  const elementsWithBG = document.querySelectorAll(".lazy-bg-img")
  elementsWithBG.forEach(el => observer.observe(el))
}
